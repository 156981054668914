import { Stack, Pod } from '../../models';
import { ComponentDivider } from '..'
import { DataStore } from "@aws-amplify/datastore";
import {
    Flex,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@aws-amplify/ui-react'
import Utils from '../../lib/utils'


import { useEffect, useState } from 'react';


export default function ClientStackAssign({ client, onCloseModal }) {
    const [stacks, setStacks] = useState([])

    function closeModal() {
        if (typeof onCloseModal === 'function') {
            return onCloseModal()
        }
        return null

    }

    async function onAssign(stack) {

        const pod = await DataStore.query(Pod, stack.podStacksId)

        const podMultiTimezone = pod.multiTimezone ?? false;
        const clientMultiTimezone = client.multiTimezone ?? false;

        if (podMultiTimezone !== clientMultiTimezone) {
            alert('Cannot assign Stack to Client because Client and Pod has different Multi-Timezone, you can only attach a stack to a client that has same multi-timezone value as Pod.');
            return;
        }

        await DataStore.save(
            Stack.copyOf(stack, updated => {
                updated.clientStacksClientId = client.clientId
            })
        );
        closeModal()
    }

    useEffect(() => {
        const stackSub = DataStore.observeQuery(Stack, (s) => s.clientStacksClientId.eq(null)
        ).subscribe(({ items }) => {
            setStacks(items);
        })

        return () => {
            stackSub.unsubscribe();
        }
    }, [])

    return (
        <Flex width="60rem" direction="column">
            {Utils.modalCloseButton(onCloseModal)}
            <ComponentDivider level={4} title="Available Stacks" />
            <Flex direction="row" justifyContent="space-between">
            </Flex>
            <Table highlightOnHover={true} variation="striped">
                <TableHead>
                    <TableRow >
                        <TableCell as="th">Stack Name</TableCell>
                        <TableCell as="th">Primary AZ</TableCell>
                        <TableCell as="th">Primary CIDR</TableCell>
                        <TableCell as="th">Secondary AZ</TableCell>
                        <TableCell as="th">Secondary CIDR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        stacks.filter(m => m.stackName).map(stack =>

                            <TableRow onClick={() => onAssign(stack)} style={{ cursor: 'pointer' }} key={stack.id}>
                                <TableCell>{stack.stackName}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az1)}</TableCell>
                                <TableCell>{stack.az1Cidr}</TableCell>
                                <TableCell>{Utils.formatRegion(stack.az2)}</TableCell>
                                <TableCell>{stack.az2Cidr}</TableCell>
                            </TableRow>
                        )}
                </TableBody>
            </Table>
        </Flex >
    );
}
